import React, { useState } from 'react';
import { Col, Row, Button, Card, CardBody, Collapse, Table, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as Constant from './../../../constants/Constant';

const ConfirmationPremiumPlan = ({ premiumResp, premiumValue }) => {
    const { t } = useTranslation();
    const t2 = (label) => { return t('en_translations:' + label) }
    const [panelOpen, setPanelOpen] = useState({
        basicBenefit: true,
        addBenefit: true
    });
    const toggleBasicBenefit = () => setPanelOpen({ ...panelOpen, basicBenefit: !panelOpen.basicBenefit });
    const toggleAddBenefit = () => setPanelOpen({ ...panelOpen, addBenefit: !panelOpen.addBenefit });
    const planSelected = premiumValue.planSelected;
    if (premiumValue) return (
        <div className="mt-3">
            <Row><Col><h5>{t('premiumPlan')}</h5></Col></Row>
            <Row><Col><h6><i>{t2('premiumPlan')}</i></h6></Col></Row>
            <Button className={"accordion-toggle mt-3 " + (panelOpen.basicBenefit ? "collapse-icon" : "expand-icon")}
                onClick={e => toggleBasicBenefit(e)}>{t('basicbenefits')}<br></br><i>{t2("basicbenefits")}</i>
            </Button>
            <Collapse isOpen={panelOpen.basicBenefit}>
                <Card><CardBody>
                    {premiumResp.plans && premiumResp.plans[0].benefits ?
                        <Table bordered key={planSelected.code}>
                            <thead>
                                <tr>
                                    <th>{t('code')}</th>
                                    <th>{t('description')}</th>
                                    <th>{t('Amount')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {premiumResp.plans.map((plan, j) => {
                                    if (plan.code === planSelected.code) {
                                        return (plan.benefits.map((currentBenefit, i) => {
                                            return (<tr key={i}>
                                                <td>{currentBenefit.code}</td>
                                                <td>{currentBenefit.desc}</td>
                                                <td>Rp. {parseInt(currentBenefit.displayValue).toLocaleString(Constant.defaultLocale)}</td>
                                            </tr>);
                                        }));                    
                                    }
                                })}
                            </tbody>
                        </Table>
                        : ""}
                </CardBody></Card>
            </Collapse>

            {premiumValue.additionalBenefitsChecked ?
                premiumValue.additionalBenefitsChecked.find(addBenefit => addBenefit.checked === true && planSelected.premium.additionalBenefitPremium > 0) ? (
                    <div>
                        <Button className={"accordion-toggle mt-3 " + (panelOpen.addBenefit ? "collapse-icon" : "expand-icon")}
                            onClick={e => toggleAddBenefit(e)}>{t('additionalbenefits')}<br></br><i>{t2("additionalbenefits")}</i>
                        </Button>
                        <Collapse isOpen={panelOpen.addBenefit}>
                            <Card><CardBody>
                                {premiumValue.additionalBenefitsChecked ?
                                    premiumValue.additionalBenefitsChecked.find(addBenefit => addBenefit.checked === true) ?
                                        <Table bordered>
                                            <thead><tr><th>{t('code')}</th><th>{t('description')}</th><th>{t('Amount')}</th></tr></thead>
                                            <tbody>
                                                {premiumResp.plans.map((plan, j) => {
                                                    if (plan.code === planSelected.code) {
                                                        return (plan.additionalBenefits.map((addBenefit, i) => {
                                                            const isChecked = premiumValue.additionalBenefitsChecked.find(checkedBenefit => 
                                                                checkedBenefit.code === addBenefit.code && checkedBenefit.checked === true
                                                            );

                                                            if (isChecked) {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{addBenefit.code}</td>
                                                                        <td>{addBenefit.desc}</td>
                                                                        <td>Rp. {parseInt(addBenefit.displayValue).toLocaleString(Constant.defaultLocale)}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        }));
                                                    }
                                                })}
                                            </tbody>
                                        </Table> : "" : ""}
                            </CardBody></Card>
                        </Collapse>
                    </div>) : null : null}
        </div>
    )
    else return "";
};

export default ConfirmationPremiumPlan;