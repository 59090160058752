import React, { useState } from 'react';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import Select from '../../../utils/Select';
import { useTranslation } from 'react-i18next';
import * as Constant from './../../../constants/Constant';
import $ from 'jquery';

const PremiumPlan = ({ 
    channel,
    respData,
    premiumResp, 
    premiumValue, 
    errorMessage, 
    setErrorMessage, 
    nextSlideTransitions, 
    handlePlan,
    showSumInsured,
    selectBasicPremium,
    additionalBenefitChecked,
    planOnClick,
    getPromoCode,
    getRecalculate,
    recalculateFlag,
    setPromoCodeInputField,
    isPlanSelectionHide
}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [item] = useState("premium");
    
    if (premiumResp.plans.length) return (
        <div>
        <Row className="mt-3"><Col className="titleH4"><h4>{t('premiumPlan')}</h4></Col></Row>
        <Row><Col className="titleH4"><h6>{t2('premiumPlan')}</h6></Col></Row>
        <Row><Col className="borderBottom"></Col></Row>
        <div className="mt-3">
            {premiumResp.additionalBenefits || !recalculateFlag ? <center><Button onClick={()=>{getRecalculate()}}>{t('button.recalculate')}</Button></center> : null}
            {
                premiumResp.plans.map((plan, i)=> {
                    return (
                        <div key={"plan"+i} className="mt-3">
                            <Table className={$('input[type=radio]:checked').val() === plan.code ? "premiumPlanSelectedTable" : "premiumPlanTable"}>
                                <thead>
                                    <tr>
                                        <td colSpan={2} className="titleH4 text-align-center background-lightGrey">{plan.name}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        showSumInsured && plan.coverages ?
                                                    plan.coverages.map((coverage, j) => {
                                                        return (
                                                            <tr key={"plan_"+i+"_coverage_"+j}>
                                                                <td className="backgroundWhite">{coverage.desc}</td>
                                                                <td>
                                                                    <div className="td-input-float text-align-center">{coverage.value ? "Rp. " + parseInt(coverage.value).toLocaleString(Constant.defaultLocale) : "-"}</div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                        : null
                                    }
                                    <tr>
                                        <td className="background-lightGrey">{t('basicbenefits')}<br></br><i>{t2("basicbenefits")}</i></td>
                                        <td className="background-lightGrey"></td>
                                    </tr>
                                    {premiumResp.plans[0].benefits ?
                                        premiumResp.plans[0].benefits.map((basicBenefit,j) => {
                                                return (
                                                <tr key={j}>
                                                    <td colSpan={2} className="backgroundWhite">
                                                        <div className="whiteSpacePre">
                                                            {basicBenefit.desc}
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                                        <center><div>-</div></center>
                                                    </td> */}
                                                </tr>
                                                )
                                        })
                                    : null }
                                    {premiumResp.plans[0].additionalBenefits ?
                                        premiumResp.plans[0].additionalBenefits.length ?
                                        <tr>
                                            <td className="background-lightGrey">{t('additionalbenefits')}<br></br><i>{t2("additionalbenefits")}</i></td>
                                            <td className="background-lightGrey"></td>
                                        </tr>
                                    : null : null }
                                    {premiumResp.plans[0].additionalBenefits ?
                                        premiumResp.plans[0].additionalBenefits.map((additionalBenefit,j) => {
                                            return (
                                                <tr key={j}>
                                                    <td colSpan={2} className="backgroundWhite displayFlex">
                                                        <input 
                                                            className="floatLeft"
                                                            name={additionalBenefit.code + "_" + j} 
                                                            type="checkbox"
                                                            onChange={(e)=>additionalBenefitChecked(e, additionalBenefit, j)}
                                                            checked={premiumValue.additionalBenefitsChecked.length ?
                                                                premiumValue.additionalBenefitsChecked[j] ?
                                                                premiumValue.additionalBenefitsChecked[j].checked : false : false
                                                            }
                                                        />
                                                        &emsp;<Label className="floatLeft additionBenefitsDesc">{additionalBenefit.desc}</Label>
                                                    </td>
                                                    {/* <td>
                                                        <center><div>-</div></center>
                                                    </td> */}
                                                </tr>
                                            )
                                        })
                                    : null }

                                    {channel === Constant.LOGIN_CHANNEL.CONSUMER && ( 
                                        <tr>
                                            <td className="background-lightGrey">{t('promoCode')}<br></br><i>{t2("promoCode")}</i></td>
                                            <td colSpan={premiumResp.plans.length} className="background-lightGrey">  
                                            <input className='input-group mb-1' type="text" value={premiumValue["promoCode"]} onChange={e => (setPromoCodeInputField(item, e.target.value))}></input>
                                            <Button className="btn-sm" name="planSelected" onClick={() => getPromoCode(item)} >{t('button.submit.promoCode')}</Button>
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td className="background-lightGrey">{t('premiumPayable')}<br></br><i>{t2("premiumPayable")}</i></td>
                                        <td className="background-lightGrey"></td>
                                    </tr>
                                    <tr>
                                        <td className="backgroundWhite">{t("duration")} ({t("duration."+respData.durationMeasurement)})<br></br><i>{t2("duration")} ({t2("duration."+respData.durationMeasurement)})</i></td>
                                        <td key={i} className={plan.premiums.length > 1 ? "text-align-center tr-input" : "text-align-center" }>
                                            {
                                                plan.premiums.length >1 ?
                                                    <div className="floatRight">
                                                        <Select 
                                                            id={"duration_"+plan.code}
                                                            defaultVal={premiumValue.planSelected.premium.duration && premiumValue.planSelected.code === plan.code ? premiumValue.planSelected.premium.duration : plan.premiums[0].duration}
                                                            noBlankSelectionFlag={true}
                                                            onChange={(e)=>selectBasicPremium(e, plan)}
                                                            optionList={
                                                                plan.premiums.map((premium)=> {
                                                                    return {shortCode: premium.duration, value: premium.text+" ( Rp. "+parseInt(premium.basicPremium).toLocaleString(Constant.defaultLocale)+")"}
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                : plan.premiums[0].duration
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="backgroundWhite">
                                            {t("basicPremium")}
                                            <br></br><i>{t2("basicPremium")}</i>
                                        </td>
                                        <td key={i} className="text-align-center">
                                            <span id={"basicPremiumText_"+plan.code}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="backgroundWhite">
                                            {t("totalPremium")}<br></br><i>{t2("totalPremium")}</i>
                                        </td>
                                        <td key={i} className="text-align-center">
                                            <span id={"grossPremiumText_"+plan.code}/><br></br>
                                            {!isPlanSelectionHide && <input type="radio" id={"planSelected_"+plan.code} value={plan.code} name="planSelected" onClick={()=>planOnClick(plan, i)} />}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )
                })
            }
        </div>
        <Row className="mt-5">
            <Col>
                <center>
                    <Button onClick={() => {nextSlideTransitions(false);window.scrollTo(0, 0);if (errorMessage) setErrorMessage("");}}>{t('button.previous')}</Button><> </>
                    <Button onClick={() => handlePlan()}>{t('button.next')}</Button>
                </center>
            </Col>
        </Row>
        </div>
    )
    else return "";
};

export default PremiumPlan;