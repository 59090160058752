import React, { useState, useEffect } from 'react';
import { Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import companyLogo from './../../images/gel-logo.png';
import { useDispatch } from 'react-redux';
import { Get, Post, FileGateway } from './../../utils/AxFetch';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import * as Validate from './../../utils/CommonValidate';
import $ from 'jquery';
import './../../App.css';
import * as actions from './../../actions/loginAction';

const ClaimReuploadSubmissionForm = (props) => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [message,setMessage] = useState('');
    const [respData, setRespData] = useState('');
    const params = new URLSearchParams(props.location.search); 
    const emailKey = params.get('key');

    useEffect(() => {
        dispatch({ type: actions.UPDATE_TOKEN, token: '' });
        const retrieveForm = async() => {
            let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_CLAIM_REUPLOAD,{params: {key: emailKey }}, dispatch);
            if (!resp.isError) {
                if (resp.respData.data) {
                    dispatch({ type: actions.UPDATE_TOKEN, token: resp.respData.data.token });
                    setRespData(resp.respData.data);
                }
            }
        }
        if (emailKey) dispatch(retrieveForm);
    }, [dispatch, emailKey])

    const validateForm = () => {
        if (respData.documents) {
            let message = '';
            let mandatoryErrMsg, fileNameErrMsg, exceedFileSizeErrMsg, invalidExtension  = false;
            let validateFile= respData.documents.map((doc, i) => {
                if (doc.remarks) {
                    if (!$('#' + doc.documentCode).val()) {
                        mandatoryErrMsg = true;
                        return false;
                    }
                }

                $( ":input" ).each(function(index, field) {
                    if (field.files) {
                        for(var i=0;i<field.files.length;i++) {
                            const file = field.files[i];
                            if (file.name.length > Constant.fileLength) {
                                fileNameErrMsg = true;
                            }
                            if (!Validate.validateFileSize(file.size, Constant.fileSize*Constant.megabyte)) { 
                                exceedFileSizeErrMsg = true;
                            }
                            if (!Validate.validateFileExtensions(file.name, Constant.filePathType)) { 
                                invalidExtension = true;
                            }  
                        }
                    }
                })

                if (fileNameErrMsg || exceedFileSizeErrMsg || invalidExtension) {
                    return false;
                } else {
                    return true;
                }
            })

            if (validateFile.includes(false)) {
                if (mandatoryErrMsg) {
                    message += 'Upload document required for claim processing.\n';
                }
                if (fileNameErrMsg) {
                    message += 'File name maximum length is ' + Constant.fileLength +'.\n';
                }
                if (exceedFileSizeErrMsg) {
                    message += 'File too big, please select a file less than ' + Constant.fileSize + 'mb.\n';
                }
                if (invalidExtension) {
                    message += 'File type not allowed.\n';
                }
                setMessage(message);
                window.scrollTo(0, 0);
                return false;
            } else {
                setMessage(message); 
                return true;
            }
        } else return false;
    };

    const handleFile = (obj) => {

        let documentCode = obj.target.id;
        let file = obj.target.files[0];
        let checkDocumentCode = object => object.documentCode === obj.target.id;

        if (fileList.some(checkDocumentCode)) {
            setFileList(
                fileList.map(fileDetail =>
                    fileDetail.documentCode === obj.target.id ? { ...fileDetail, file: file } : { ...fileDetail }
                )
            )
        } else {
            setFileList(prevState => [...prevState, {documentCode: documentCode, file: file}]);
        }
    };

    const handleSubmit = async() => {
        if (validateForm()) {
            let isRespFile = false;
            for (let index = 0; index < fileList.length; index++) {
                let file = fileList[index].file;
                let resp = await FileGateway(respData.claimRefNo+'^'+fileList[index].documentCode,
                file, Constant.FG_CH_fileUpload, dispatch);
                if (!resp.isError) {
                    isRespFile = true;
                } else {
                    if (resp.respData === Constant.FG_SCAN_VIRUS_FAILED) {
                        $('#'+respData.documents[index].documentCode).val('');
                    }
                    window.scrollTo(0, 0);
                    isRespFile = false;
                    break;
                }
            }

            if (isRespFile) {
                let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_CLAIM_REUPLOAD_SUBMIT, 
                    {
                        id: respData.id,
                        claimRefNo: respData.claimRefNo
                    }
                    , dispatch);
                if (!resp.isError) {
                    if (resp.respData.data) {
                        setRespData(resp.respData.data);
                        setModalIsOpen(true);
                    }
                }
            }
        }
    }

    const handleCloseModal = () => setModalIsOpen(false);
    const handleExit = () => window.close();
    const handleClose = () => window.close();

    if (respData) {
        return (
            <div>
                <div className="auth-top claim"><img src={companyLogo} className="company-logo mx-auto d-block" alt="username" /></div>
                <Container className="auth-inner claim">
                    { message? <div className="error"><p>{message}</p></div>: null}
                    <Row className="mt-3"><Col><h2>Pendaftaran Klaim</h2></Col></Row>
                    <Row><Col><h4><i>Claim Registration</i></h4></Col></Row><br></br>
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label>Nomor Sertifikat Asuransi<br></br><i>Certificate Insurance No.</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{respData.policyNo}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>No Klaim<br></br><i>Claim No.</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{respData.claimRefNo}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Status<br></br><i>Status</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{respData.statusDesc}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Nomor Handphone<br></br><i>Mobile No.</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{respData.mobileNo}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Surat Elektronik<br></br><i>Email</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{respData.email}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Nama<br></br><i>Name</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{respData.proposerName}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Nama Produk<br></br><i>Product Name</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{respData.productName}</Col>    
                        </Row>
                        <div className="content-tables">
                            <Table id="basicBeneTable" bordered className="mt-3" style={{float:'left',width: '50%'}}> 
                                <thead className='text-center claim-table-header'>
                                    <tr>
                                        <th colSpan="2">Manfaat Dasar<i> / Basic Benefit</i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {respData.basicBenefits ?
                                        respData.basicBenefits.length > 0 ?
                                        respData.basicBenefits.map((benefit,i) => {
                                            return <tr key={benefit.desc}><td className='longBenefitDesc'>{benefit.desc}</td><td>Rp. {parseInt(benefit.displayValue).toLocaleString(Constant.defaultLocale)}</td></tr>
                                            }) : <tr><td>No Data</td></tr>
                                        : <tr><td>No Data</td></tr>
                                    }
                                </tbody>
                            </Table>
                            <Table id="addBeneTable" bordered className="mt-3" style={{float:'left',width: '50%'}}> 
                                <thead className='text-center claim-table-header'>
                                    <tr>
                                        <th colSpan="2">Manfaat Tambahan<i> / Additional Benefit</i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {respData.additionalBenefits ? 
                                        respData.additionalBenefits.length > 0 ? 
                                        respData.additionalBenefits.map((addBenefit,i) => {
                                            return <tr key={addBenefit.desc}><td className='longBenefitDesc'>{addBenefit.desc}</td><td>Rp. {parseInt(addBenefit.displayValue).toLocaleString(Constant.defaultLocale)}</td></tr>
                                            }) : <tr><td>No Data</td></tr>
                                        : <tr><td>No Data</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <Row><Col><strong>Persyaratan Dokumen Untuk Proses Klaim</strong><i> / Document Required for Claim Processing</i></Col></Row>
                        <Table bordered className="mt-3"> 
                            <thead className='text-center claim-table-header'>
                                <tr>
                                    <th>Jenis Dokumen<i> / Document Type</i></th>
                                    <th>Deskripsi Dokumen<i> / Document Description</i></th>
                                    <th>Tanggal Unggah<i> / Upload Date</i></th>
                                    <th>Catatan Pemeriksaan Dokumen<i> / Document Review Remark</i></th>
                                    <th>Unggah Dokumen<i> / Upload Document</i></th>
                                </tr>
                            </thead>
                            <tbody>
                                {respData.documents ?
                                    respData.documents.map((doc, i) => {
                                        return <tr key={doc.documentType}>
                                            <td>{doc.documentType}</td>
                                            <td>{doc.documentDesc}</td>
                                            <td>{doc.lastModifiedDatetime}</td>
                                            <td>{doc.remarks}</td>
                                            <td>
                                                {
                                                    respData.status === Constant.PEND_REUPL && doc.remarks ?
                                                        <Input id={doc.documentCode} type='file' onChange={e => handleFile(e)}/>
                                                        : doc.fileName
                                                }
                                            </td>
                                        </tr>
                                    }) : <tr/>
                                }
                            </tbody>
                        </Table>
                        <Row className="mt-3">
                            <Col xs="10" sm={{ size: 3 }} md="3"><Label>Tanggal Kejadian<br></br><i>Date of loss</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col sm={{ size: 2 }} md="2">{respData.incidentDateStg}</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label>Nilai Klaim (Rp)<br></br><i>Claim Amount (Rp)</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col sm="8" md="8">{respData.claimAmountStg}</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label>Penjelasan & Kronologi Klaim<br></br><i>Claim Description & Chronology</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col sm="8" md="8">{respData.claimRemark}</Col>
                        </Row>
                        {respData.status === Constant.PEND_REUPL ?
                            <div className="text-center">
                                <Button className="mt-3" onClick={handleSubmit}>Submit</Button>{' '}
                                <Button className="mt-3" onClick={handleExit}>Cancel</Button>
                            </div>
                            :
                            <div className="text-center">
                                <Button className="mt-3" onClick={handleClose}>Close</Button>
                            </div>
                        }
                </Container>
                <Modal isOpen={modalIsOpen} className="modal-dialog">
                    <ModalHeader className="modal_header">
                        Pesan<i> / Message</i>
                    </ModalHeader>
                    <ModalBody className="report-modal-body">
                        Formulir Klaim telah berhasil diajukan.<br></br>
                        <i>Claim Form submitted successfully.</i>
                    </ModalBody>
                    <ModalFooter><Button className="mt-3 mx-auto" onClick={handleCloseModal}>OK</Button></ModalFooter>
                </Modal>
            </div>
        )
    } else return false;
}

export default ClaimReuploadSubmissionForm;