import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import Select from '../../../utils/Select';
import * as Constant from './../../../constants/Constant';
import { useTranslation } from 'react-i18next';

const PremiumPlan = ({ 
        channel,
        respData,
        premiumResp, 
        premiumValue, 
        errorMessage, 
        setErrorMessage, 
        nextSlideTransitions, 
        handlePlan,
        showSumInsured,
        selectBasicPremium,
        additionalBenefitChecked,
        planOnClick,
        getPromoCode,
        getRecalculate,
        recalculateFlag,
        setPromoCodeInputField,
        isPlanSelectionHide
}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const [item] = useState("premium");

    if (premiumResp.plans.length) return (
        <div>
        <Row className="mt-3"><Col className="titleH4"><h4>{t('premiumPlan')}</h4></Col></Row>
        <Row><Col className="titleH4"><h6>{t2('premiumPlan')}</h6></Col></Row>
        <Row><Col className="borderBottom"></Col></Row>
        <div className="mt-3">
            <div className="view">
            <div className="wrapper">
            <Table bordered>
                <thead>
                    <tr>
                        <td className="titleH4 background-lightGrey sticky-col first-col">{t("selectPlan")}<br></br><i>{t2("selectPlan")}</i></td>
                        {premiumResp.plans.map((plan, i) => {
                            return <td key={"plan"+i} className="titleH4 text-align-center background-lightGrey">{plan.name}</td>
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr className={showSumInsured? "" : "hideTable"}>
                        <td className="sticky-col first-col backgroundWhite">{t('infosuminsured')}</td>
                        {
                            showSumInsured ?
                                premiumResp.plans.map((plan, i) => {
                                    if (plan.coverages) {
                                        return plan.coverages.map((coverage, j) => {
                                            if (coverage.value) {
                                                return (
                                                    <td key={i+j}>
                                                        <div className="td-input-float">{"Rp. "+parseInt(coverage.value).toLocaleString(Constant.defaultLocale)}</div>
                                                    </td>
                                                )
                                            }else {
                                                return (
                                                    <td key={i+j}>
                                                        <div className="td-input-float">-</div>
                                                    </td>
                                                )
                                            }
                                        })
                                    } else return null;
                                })
                            : null
                        }
                    </tr>
                    <tr>
                        <td className="background-lightGrey sticky-col first-col">{t('basicbenefits')}<br></br><i>{t2("basicbenefits")}</i></td>
                        <td colSpan={premiumResp.plans.length} className="background-lightGrey"></td>
                    </tr>
                    {
                        premiumResp.plans[0].benefits.map((benefit, j) => (
                            <tr key={j}>
                                <td className="sticky-col first-col backgroundWhite">
                                    {/* <div style={{whiteSpace:"normal",wordWrap:"break-word",overflowWrap:"break-word",maxWidth: "270px"}} className="whiteSpacePre">{benefit.desc}</div> */}
                                    <div className="whiteSpacePre longBenefitDesc">{benefit.desc}</div>
                                </td>
                                {premiumResp.plans.map((plan, i) => {
                                    const currentBenefit = plan.benefits.find(b => b.code === benefit.code);
                                    return (
                                        <td key={plan.code}>
                                            <div className="td-input-float">
                                                {currentBenefit ? "Rp. " + parseInt(currentBenefit.displayValue).toLocaleString(Constant.defaultLocale) : 'N/A'}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))
                    }
                    {premiumResp.plans[0].additionalBenefits ?
                        premiumResp.plans[0].additionalBenefits.length ?
                        <tr>
                            <td className="background-lightGrey sticky-col first-col">{t('additionalbenefits')}<br></br><i>{t2("additionalbenefits")}</i></td>
                            <td colSpan={premiumResp.plans.length} className="background-lightGrey"></td>
                        </tr>
                    :null:null}
                    {
                        premiumResp.plans[0].additionalBenefits.map((additionalBenefit,j) => {
                            return(
                            <tr key={j}>
                                <td className="sticky-col first-col backgroundWhite">
                                    <div className="whiteSpacePre longBenefitDesc">
                                    <input 
                                        id={additionalBenefit.code} 
                                        type="checkbox"
                                        onChange={(e)=>additionalBenefitChecked(e, additionalBenefit, j)}
                                        checked={premiumValue.additionalBenefitsChecked.length ?
                                            premiumValue.additionalBenefitsChecked[j] ?
                                            premiumValue.additionalBenefitsChecked[j].checked : false : false
                                        }
                                        />&emsp;
                                        {additionalBenefit.desc}
                                    </div>
                                </td>
                                {premiumResp.plans.map((plan, i) => {
                                    const currentAdditionalBenefit = plan.additionalBenefits.find(b => b.code === additionalBenefit.code);
                                    return (
                                        <td key={plan.code}>
                                            <div className="td-input-float">
                                                {currentAdditionalBenefit ? currentAdditionalBenefit.displayValue!=0 ? "Rp. " + parseInt(currentAdditionalBenefit.displayValue).toLocaleString(Constant.defaultLocale) : 'N/A' : "N/A"}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                            )
                        })
                    }

                    
                        <tr>
                            <td className="background-lightGrey sticky-col first-col">{t('promoCode')}<br></br><i>{t2("promoCode")}</i></td>
                            <td colSpan={premiumResp.plans.length} className="background-lightGrey">  
                            <input className='input-group mb-1 col-md-5' type="text" value={premiumValue["promoCode"]} maxLength={30} onChange={e => (setPromoCodeInputField(item, e.target.value))}></input>
                            <Button disabled={!premiumValue.promoCode}  className="btn-sm" name="planSelected" onClick={() => getPromoCode(item)} >{t('button.submit.promoCode')}</Button>
                            </td>
                        </tr>
                   
                    <tr>
                        <td className="background-lightGrey sticky-col first-col">{t('premiumPayable')}<br></br><i>{t2("premiumPayable")}</i></td>
                        <td colSpan={premiumResp.plans.length} className="background-lightGrey"></td>
                    </tr>
                    <tr>
                        <td className="sticky-col first-col">{t("duration")} ({t("duration."+respData.durationMeasurement)})<br></br><i>{t2("duration")} ({t2("duration."+respData.durationMeasurement)})</i></td>
                        {premiumResp.plans.map((plan, i) => {
                            if(plan.code == premiumValue.planSelected.code) {
                                return (
                                    <td key={i} className={plan.premiums.length > 1 ? "text-align-right tr-input selectedPlanDuration" : "text-align-right selectedPlanDuration" }>
                                        {
                                            plan.premiums.length >1 ?
                                                <div className="floatRight">
                                                    <Select 
                                                        id={"duration_"+plan.code}
                                                        defaultVal={premiumValue.planSelected.premium.duration && premiumValue.planSelected.code === plan.code ? premiumValue.planSelected.premium.duration : plan.premiums[0].duration}
                                                        noBlankSelectionFlag={true}
                                                        onChange={(e)=>selectBasicPremium(e, plan)}
                                                        optionList={
                                                            plan.premiums.map((premium)=> {
                                                                return {shortCode: premium.duration, value: premium.text+" ( Rp. "+parseInt(premium.basicPremium).toLocaleString(Constant.defaultLocale)+")"}
                                                            })
                                                        }
                                                    />
                                                </div>
                                            : plan.premiums[0].duration
                                        }
                                    </td>
                                )
                            }
                            return (
                                <td key={i} className={plan.premiums.length > 1 ? "text-align-right tr-input" : "text-align-right" }>
                                    {
                                        plan.premiums.length >1 ?
                                            <div className="floatRight">
                                                <Select 
                                                    id={"duration_"+plan.code}
                                                    defaultVal={premiumValue.planSelected.premium.duration && premiumValue.planSelected.code === plan.code ? premiumValue.planSelected.premium.duration : plan.premiums[0].duration}
                                                    noBlankSelectionFlag={true}
                                                    onChange={(e)=>selectBasicPremium(e, plan)}
                                                    optionList={
                                                        plan.premiums.map((premium)=> {
                                                            return {shortCode: premium.duration, value: premium.text+" ( Rp. "+parseInt(premium.basicPremium).toLocaleString(Constant.defaultLocale)+")"}
                                                        })
                                                    }
                                                />
                                            </div>
                                        : plan.premiums[0].duration
                                    }
                                </td>
                            )
                        })}
                    </tr>
                    <tr>
                        <td className="sticky-col first-col">
                            {t("basicPremium")}
                            <br></br><i>{t2("basicPremium")}</i>
                        </td>
                        {premiumResp.plans.map((plan, i) => {
                            if(plan.code == premiumValue.planSelected.code) {
                                return (
                                    <td key={i} className="text-align-right selectedBasicPrem">
                                        <span id={"basicPremiumText_"+plan.code}/>
                                    </td>
                                )
                            }
                            return (
                                <td key={i} className="text-align-right">
                                    <span id={"basicPremiumText_"+plan.code}/>
                                </td>
                            )
                        })}
                    </tr>
                    {premiumResp.plans[0].additionalBenefits ?
                        premiumResp.plans[0].additionalBenefits.length ?
                            <tr>
                                <td className='sticky-col first-col'>
                                    {t("additionalPremium")}<br /><i>{t("additionalPremium")}</i>
                                </td>
                                {premiumResp.plans.map((plan, i) => {
                                    const currentPremium = plan.premiums.find(premium => premium.additionalBenefitPremium)?.additionalBenefitPremium;
                                    if (plan.code == premiumValue.planSelected.code) {
                                         return (
                                            <td key={plan.code} className="text-align-right selectedCol">
                                                <div className="d-flex flex-column justify-content-end align-items-end">
                                                Rp. {currentPremium ? parseInt(currentPremium).toLocaleString(Constant.defaultLocale): 0}
                                                </div>
                                            </td>
                                        )
                                    }
                                    return (
                                        <td key={plan.code} className="text-align-right">
                                            <div className="d-flex flex-column justify-content-end align-items-end">
                                            Rp. {currentPremium ? parseInt(currentPremium).toLocaleString(Constant.defaultLocale): 0}
                                            </div>
                                        </td>)
                                })}
                            </tr>: null : null}
                    <tr className="tableRow-grossPremium">
                        <td className="sticky-col first-col">
                            <div className="floatLeft">{t("totalPremium")}<br></br><i>{t2("totalPremium")}</i></div>
                        </td>

                        {premiumResp.plans.map((plan, i) => {
                            const premium = plan.premiums.find(p => p.netPremium !== null);
                            if(plan.code == premiumValue.planSelected.code){
                                return (
                                    <td key={i} className="text-align-right selectedTotalPremium">
                                        <span id={"grossPremiumText_"+plan.code} className={premium?'strikethrough':''}/>
                                        <div>{premium?"Rp. "+ parseInt(premium.netPremium).toLocaleString(Constant.defaultLocale):''}</div>
                                    </td>
                                )
                            }
                            return(
                                <td key={i} className="text-align-right">
                                    <div className="d-flex flex-column justify-content-end align-items-end">
                                        <span id={"grossPremiumText_"+plan.code} className={premium?'strikethrough':''}/>
                                        {premium?"Rp. "+ parseInt(premium.netPremium).toLocaleString(Constant.defaultLocale):''}
                                    </div>
                                </td>
                            )
                        })}
                    </tr>
                    
                    {premiumResp.plans.some(plan=>plan.premiums.some(premium=>premium.adminFee)) && (
                        <tr>
                            <td className='sticky-col first-col'>
                                <div className="floatLeft">{t("adminFee")}<br /><i>{t2("adminFee")}</i></div>
                            </td>
                            {premiumResp.plans.map((plan, i) => (
                                plan.code == premiumValue.planSelected.code?
                                (
                                    <td key={i} className="text-align-right selectedCol">
                                        {plan.premiums.map((premium, j) => (
                                            <div key={j}>Rp. {parseInt(premium.adminFee).toLocaleString(Constant.defaultLocale)}</div>
                                        ))}
                                    </td>
                                )
                                :
                                (
                                    <td key={i} className="text-align-right">
                                        {plan.premiums.map((premium, j) => (
                                            <div key={j}>Rp. {parseInt(premium.adminFee).toLocaleString(Constant.defaultLocale)}</div>
                                        ))}
                                    </td>
                                )
                            ))}
                        </tr>
                    )}
                    {premiumResp.plans.some(plan=>plan.premiums.some(premium=>premium.stampDuty)) && (
                        <tr>
                            <td className='sticky-col first-col'>
                                <div className="floatLeft">{t("stampDuty")}<br /><i>{t2("stampDuty")}</i></div>
                            </td>
                            {premiumResp.plans.map((plan,i)=>(
                                plan.code == premiumValue.planSelected.code?
                                (
                                    <td key={i} className='text-align-right selectedCol'>
                                        {plan.premiums.map((premium,j)=>(
                                            <div key={j}>Rp. {parseInt(premium.stampDuty).toLocaleString(Constant.defaultLocale)}</div>
                                        ))}
                                    </td>
                                )
                                :
                                (
                                    <td key={i} className='text-align-right'>
                                        {plan.premiums.map((premium,j)=>(
                                            <div key={j}>Rp. {parseInt(premium.stampDuty).toLocaleString(Constant.defaultLocale)}</div>
                                        ))}
                                    </td>
                                )
                            ))}
                        </tr>
                    )}
                    {premiumResp.plans.some(plan=>plan.premiums.some(premium=>premium.discountAmount)) && (
                        <tr>
                            <td className='sticky-col first-col'>
                                <div className="floatLeft">{t("discountAmount")}<br /><i>{t2("discountAmount")}</i></div>
                            </td>
                            {premiumResp.plans.map((plan,i)=>(
                                plan.code == premiumValue.planSelected.code?
                                (
                                    <td key={i} className='text-align-right selectedCol'>
                                        {plan.premiums.map((premium,j)=>(
                                            <div key={j}>Rp. {parseInt(premium.discountAmount).toLocaleString(Constant.defaultLocale)}</div>
                                        ))}
                                    </td>
                                )
                                :
                                (
                                    <td key={i} className='text-align-right'>
                                        {plan.premiums.map((premium,j)=>(
                                            <div key={j}>Rp. {parseInt(premium.discountAmount).toLocaleString(Constant.defaultLocale)}</div>
                                        ))}
                                    </td>
                                )
                                
                            ))}
                        </tr>
                    )}
                    <tr>
                        <td className='sticky-col first-col'>
                            <div className="floatLeft">{t("grandtotal")}<br /><i>{t2("grandtotal")}</i></div>
                        </td>
                        {premiumResp.plans.map((plan,i)=>(
                            plan.code == premiumValue.planSelected.code?
                            (
                                <td key={i} className='text-align-right selectedCol'>
                                    {plan.premiums.map((premium,j)=>(
                                        <div key={j}>Rp. {parseInt(premium.grandTotal).toLocaleString(Constant.defaultLocale)}</div>
                                    ))}
                                </td>
                            )
                            :
                            (
                                <td key={i} className='text-align-right'>
                                    {plan.premiums.map((premium,j)=>(
                                        <div key={j}>Rp. {parseInt(premium.grandTotal).toLocaleString(Constant.defaultLocale)}</div>
                                    ))}
                                </td>
                            )
                        ))}
                    </tr>
                    
                    <tr>
                        <td>
                            { !recalculateFlag ? 
                            <div className="btn-recalculate">
                                <Button className="btn-sm" onClick={()=>{getRecalculate()}}>{t('button.recalculate')}</Button>
                            </div>
                            : null}
                        </td>
                        
                            {premiumResp.plans.map((plan, i) => {
                                if(plan.code == premiumValue.planSelected.code) {
                                    return(
                                        <td key={i} className="text-align-right selectedPlan">
                                            <div className="d-flex flex-column justify-content-end align-items-end">
                                                {!isPlanSelectionHide && <Button className="btn-sm" id={"planSelected_"+plan.code} value={plan.code} name="planSelected" onClick={()=>planOnClick(plan, i)} >Pilih Plan</Button>}
                                            </div>
                                        </td>
                                    )
                                }
                                return (
                                    <td key={i} className="text-align-right">
                                        <div className="d-flex flex-column justify-content-end align-items-end">
                                            {!isPlanSelectionHide && <Button className="btn-sm" id={"planSelected_"+plan.code} value={plan.code} name="planSelected" onClick={()=>planOnClick(plan, i)} >Pilih Plan</Button>}
                                        </div>
                                    </td>
                                )
                            })}
                    </tr>
                </tbody>
            </Table>
            </div>
            </div>
        </div>
        <Row className="mt-5">
            <Col>
                <center>
                    <Button onClick={() => {nextSlideTransitions(false);window.scrollTo(0, 0);if (errorMessage) setErrorMessage("");}}>{t('button.previous')}</Button><> </>
                    <Button onClick={() => handlePlan()}>{t('button.next')}</Button>
                </center>
            </Col>
        </Row>
        </div>
    )
    else return "";
};

export default PremiumPlan;