import React, { useState, useEffect } from 'react';
import { Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import companyLogo from './../../images/gel-logo.png';
import { useDispatch } from 'react-redux';
import { push } from "connected-react-router";
import { FileGateway, Post } from './../../utils/AxFetch';
import * as Constant from './../../constants/Constant';
import * as urlConstant from './../../constants/urlConstant';
import DatePicker from './../../components/DatePicker';
import * as Validate from './../../utils/CommonValidate';
import $ from 'jquery';
import './../../App.css';

const ClaimSubmissionForm = (props) => {
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [message,setMessage] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [data, setData] = useState(props.location.state? props.location.state.data : '');

    //Redirect to main page if no data
    useEffect(() => {
        if (!data) {
            dispatch(push(Constant.PATH_CLAIM_REGIRTRATION))
        }
    }, [dispatch, data])

    const handleCancel = () => dispatch(push(Constant.PATH_CLAIM_REGIRTRATION));

    const validateForm = () => {
        let dateOfLossError = "";
        let claimAmtError = "";
        let claimDescriptionError = "";
        let agreementCheckedError = "";
        let validateFile = [];
        let message = '';
        let mandatoryErrMsg, fileNameErrMsg, exceedFileSizeErrMsg, invalidExtension  = false;

        if (!$('input[name="dateOfLoss"]').val()) dateOfLossError = "Date of loss cannot be blank";
        if (!$('input[name="claimAmt"]').val() || !($('input[name="claimAmt"]').val() > 0)) claimAmtError = "Claim Amount cannot be blank or 0";
        if (!$('#claimDescription').val()) claimDescriptionError = "Claim Description & Chronology cannot be blank";
        if (!$('#agreement').is(':checked')) agreementCheckedError = "Please accept Terms and Conditions to submit the Claim.";

        if (data.documents) {
            validateFile= data.documents.map((doc, i) => {

                if (doc.documenRequire) {
                    if (!$(':file')[i].files.length) {
                        mandatoryErrMsg = true;
                        return false;
                    } 
                    
                    if ($(':file')[i].files[0].name.length > Constant.fileLength) {
                        fileNameErrMsg = true;
                    }
    
                    let fsize = $(':file')[i].files[0].size; 
    
                    if (!Validate.validateFileSize(fsize, Constant.fileSize*Constant.megabyte)) { 
                        exceedFileSizeErrMsg = true;
                    }  
    
                    let filePath = $('#' + doc.documentCode).val();
    
                    if (!Validate.validateFileExtensions(filePath, Constant.filePathType)) { 
                        invalidExtension = true;
                    }  
                }

                if (fileNameErrMsg || exceedFileSizeErrMsg || invalidExtension) {
                    return false;
                } else {
                    return true;
                }
            })
        }

        setErrorMsg({ dateOfLossError: dateOfLossError, claimAmtError: claimAmtError, claimDescriptionError: claimDescriptionError, agreementCheckedError: agreementCheckedError});
        if (dateOfLossError || claimAmtError || claimDescriptionError || agreementCheckedError || validateFile.includes(false) ) {
            if (validateFile.includes(false)) {
                if (mandatoryErrMsg) {
                    message += 'Upload document required for claim processing.\n';
                }
                if (fileNameErrMsg) {
                    message += 'File name maximum length is ' + Constant.fileLength +'.\n';
                }
                if (exceedFileSizeErrMsg) {
                    message += 'File too big, please select a file less than ' + Constant.fileSize + 'mb.\n';
                }
                if (invalidExtension) {
                    message += 'File type not allowed.\n';
                }
                setMessage(message);
                window.scrollTo(0, 0);
            } else setMessage(message);
            return false;
        }
        setMessage(message); 
        return true;
    };

    const handleFile = (obj) => {

        let documentCode = obj.target.id;
        let file = obj.target.files[0];
        let checkDocumentCode = object => object.documentCode === obj.target.id;

        if (fileList.some(checkDocumentCode)) {
            setFileList(
                fileList.map(fileDetail =>
                    fileDetail.documentCode === obj.target.id ? { ...fileDetail, file: file } : { ...fileDetail }
                )
            )
        } else {
            setFileList(prevState => [...prevState, {documentCode: documentCode, file: file}]);
        }
    };

    const handleSubmit = async() => {
        if (validateForm()) {
            let isRespFile = false;
            for (let index = 0; index < fileList.length; index++) {
                let file = fileList[index].file;
                let resp = await FileGateway(data.claimRefNo+'^'+fileList[index].documentCode,
                file, Constant.FG_CH_fileUpload, dispatch);
                if (!resp.isError) {
                    isRespFile = true;
                } else {
                    if (resp.respData === Constant.FG_SCAN_VIRUS_FAILED) {
                        $('#'+data.documents[index].documentCode).val('');
                    }
                    window.scrollTo(0, 0);
                    isRespFile = false;
                    break;
                }
            }

            if (isRespFile) {
                let resp = await Post(urlConstant.URL_BASED + urlConstant.URL_CLAIM_SUBMIT, 
                    {
                        id: data.id,
                        policyNo: data.policyNo,
                        incidentDateStg: $('input[name="dateOfLoss"]').val(),
                        claimAmountStg: $('input[name="claimAmt"]').val(),
                        claimRemark: $('#claimDescription').val()
                    }
                    , dispatch);
                if (!resp.isError) {
                    if (resp.respData.data) {
                        setData(resp.respData.data);
                        setModalIsOpen(true);
                        $('#agreement').attr("disabled", true);
                    }
                }
            }
        }
    }

    const handleClose = () => "INDIVIDUALPOLICY" === data.policyType ? dispatch(push(Constant.PATH_INDIVIDUAL_CLAIM_REGIRTRATION)) : dispatch(push(Constant.PATH_CLAIM_REGIRTRATION));
    const handleCloseModal = () => setModalIsOpen(false);

    if (data) {
        return (
            <div>
                <div className="auth-top claim"><img src={companyLogo} className="company-logo mx-auto d-block" alt="username" /></div>
                <Container className="auth-inner claim">
                    { message? <div className="error"><p>{message}</p></div>: null}
                    <Row className="mt-3"><Col><h2>Pendaftaran Klaim</h2></Col></Row>
                    <Row><Col><h4><i>Claim Registration</i></h4></Col></Row><br></br>
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label>Nomor Sertifikat Asuransi<br></br><i>Certificate Insurance No.</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{data.policyNo}</Col>    
                        </Row>
                        {data.status === Constant.PEND_APPVL ?
                            <><Row className="mt-2">
                                <Col xs="10" sm="3" md="3"><Label>No Klaim<br></br><i>Claim No.</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="8" md="8">{data.claimRefNo}</Col>    
                            </Row>
                            <Row className="mt-2">
                                <Col xs="10" sm="3" md="3"><Label>Status<br></br><i>Status</i></Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="8" md="8">{data.statusDesc}</Col>    
                            </Row></>
                            : <></>
                        }
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Nomor Handphone<br></br><i>Mobile No.</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{data.mobileNo}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Surat Elektronik<br></br><i>Email</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{data.email}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Nama<br></br><i>Name</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{data.proposerName}</Col>    
                        </Row>
                        <Row className="mt-2">
                            <Col xs="10" sm="3" md="3"><Label>Nama Produk<br></br><i>Product Name</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="8" md="8">{data.productName}</Col>    
                        </Row>
                        <div className="content-tables">
                            <Table id="basicBeneTable" bordered className="mt-3" style={{float:'left',width: '50%'}}> 
                                <thead className='text-center claim-table-header'>
                                    <tr>
                                        <th colSpan="2">Manfaat Dasar<i> / Basic Benefit</i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.basicBenefits ?
                                        data.basicBenefits.length > 0 ?
                                            data.basicBenefits.map((benefit,i) => {
                                                return <tr key={benefit.desc}><td className='longBenefitDesc'>{benefit.desc}</td><td>Rp. {parseInt(benefit.displayValue).toLocaleString(Constant.defaultLocale)}</td></tr>
                                            }) : <tr><td>No Data</td></tr>
                                        : <tr><td>No Data</td></tr>
                                    }
                                </tbody>
                            </Table>
                            <Table id="addBeneTable" bordered className="mt-3" style={{float:'left',width: '50%'}}> 
                                <thead className='text-center claim-table-header'>
                                    <tr>
                                        <th colSpan="2">Manfaat Tambahan<i> / Additional Benefit</i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.additionalBenefits ? 
                                        data.additionalBenefits.length > 0 ? 
                                            data.additionalBenefits.map((addBenefit,i) => {
                                                return <tr key={addBenefit.desc}><td className='longBenefitDesc'>{addBenefit.desc}</td><td>Rp. {parseInt(addBenefit.displayValue).toLocaleString(Constant.defaultLocale)}</td></tr>
                                            }) : <tr><td>No Data</td></tr>
                                        : <tr><td>No Data</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <Row><Col><strong>Persyaratan Dokumen Untuk Proses Klaim</strong><i> / Document Required for Claim Processing</i></Col></Row>
                        <Table bordered className="mt-3"> 
                            <thead className='text-center claim-table-header'>
                                <tr>
                                    <th>Jenis Dokumen<i> / Document Type</i></th>
                                    <th>Deskripsi Dokumen<i> / Document Description</i></th>
                                    <th>Wajib<i> / Mandatory</i></th>
                                    <th>Unggah Dokumen<i> / Upload Document</i></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.documents ?
                                    data.documents.map((document, i) => {
                                        return <tr key={document.documentCode}>
                                            <td>{document.documentType}</td>
                                            <td>{document.documentDesc}</td>
                                            <td>{document.documenRequire ? "Yes": "No"}</td>
                                            <td>
                                                {data.status === Constant.DRAFT ?
                                                    <Input id={document.documentCode} type='file' onChange={e => handleFile(e)} />
                                                    : document.fileName
                                                }
                                            </td>
                                        </tr>
                                    }) : <tr/>
                                }
                            </tbody>
                        </Table>
                        <Row className="mt-3">
                            <Col xs="10" sm={{ size: 3 }} md="3"><Label className="required">Tanggal Kejadian<br></br><i>Date of loss</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col sm={{ size: 2 }} md="2">
                                {data.status === Constant.DRAFT ?
                                    <DatePicker className="form-control datepicker-icon required" selected={startDate} onChange={date => setStartDate(date)} id="dateOfLoss" name="dateOfLoss" maxDate={new Date()}/>
                                        : data.status === Constant.PEND_APPVL ?
                                            data.incidentDateStg 
                                                : <></>
                                }
                            </Col>
                        </Row>
                        {errorMsg.dateOfLossError ?
                            <Row>
                                <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.dateOfLossError}</div></Col>
                            </Row>
                            : <></>
                        }
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label className="required">Nilai Klaim (Rp)<br></br><i>Claim Amount (Rp)</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col sm="8" md="8">
                                {data.status === Constant.DRAFT ? 
                                    <Input id="claimAmt" name="claimAmt" onKeyPress={(event) => Validate.number(event)} maxLength='15'></Input>  
                                        : data.status === Constant.PEND_APPVL ? 
                                            data.claimAmountStg
                                                : <></>
                                }
                            </Col>
                        </Row>
                        {errorMsg.claimAmtError ?
                            <Row>
                                <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.claimAmtError}</div></Col>
                            </Row>
                            : <></>
                        }
                        <Row className="mt-3">
                            <Col xs="10" sm="3" md="3"><Label className="required">Penjelasan & Kronologi Klaim<br></br><i>Claim Description & Chronology</i></Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col sm="8" md="8">
                                {data.status === Constant.DRAFT ? 
                                    <Input type="textarea" id="claimDescription"  maxLength='140'></Input> 
                                        : data.status === Constant.PEND_APPVL ?
                                            data.claimRemark
                                                : <></>
                                }
                            </Col>
                        </Row>
                        {errorMsg.claimDescriptionError ?
                            <Row>
                                <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.claimDescriptionError}</div></Col>
                            </Row>
                            : <></>
                        }
                        {data.status === Constant.DRAFT || data.status === Constant.PEND_APPVL ?
                        <>
                            <Row className="mt-3">
                                <Col xs="1"sm="4" md="4"></Col>
                                <Col xs="11" sm="8" md="8"><Input id='agreement' type="checkbox" />{' '}Saya menyetujui Syarat dan Ketentuan Klaim<br></br><i>I agree to accept the Terms & Conditions of Claim.</i></Col>
                            </Row>
                            <Row>
                                <Col sm={{ size: 8, offset: 4 }}><div className="error">{errorMsg.agreementCheckedError}</div></Col>
                            </Row>
                        </>    
                            :<></>
                        }
                        {data.status === Constant.DRAFT ?
                            <div className="text-center">
                                <Button className="mt-3" onClick={handleSubmit}>Submit</Button>{' '}
                                <Button className="mt-3" onClick={handleCancel}>Cancel</Button>
                            </div>
                            :
                            <div className="text-center">
                                <Button className="mt-3" onClick={handleClose}>Close</Button>
                            </div>
                        }
                </Container>
                <Modal isOpen={modalIsOpen} className="modal-dialog">
                    <ModalHeader className="modal_header">
                        Pesan<i> / Message</i>
                    </ModalHeader>
                    <ModalBody className="report-modal-body">
                        Formulir Klaim telah berhasil diajukan. No Referensi Klaim : {data.claimRefNo}<br></br>
                        <i>Claim Form submitted successfully. Reference Claim No. : {data.claimRefNo}</i>
                    </ModalBody>
                    <ModalFooter><Button className="mt-3 mx-auto" onClick={handleCloseModal}>OK</Button></ModalFooter>
                </Modal>
            </div>
        )
    } else return false;
}

export default ClaimSubmissionForm;