import React from 'react';
import { Col, Row, Label, CardBody, Card} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as constant from '../../../constants/Constant';

const ConfirmationPlan = ({ premiumValue, respData }) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const planSelected = premiumValue.planSelected;

    if (planSelected) return (        
        <div className="mt-3">
            <Row><Col xs="12" sm="12" md="12"><h5>{t('planChoice')}</h5></Col></Row>
            <Row><Col xs="12" sm="12" md="12"><h6>{t2('planChoice')}</h6></Col></Row>
            <Card className="bg-light mt-3"><CardBody>
                <Row><Col xs="12" sm="12" md="12" className="titleH4"><h5>{planSelected.name} </h5></Col></Row>
                <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                <div className="font-weight-bold">
                <Row>
                    <Col xs="6" sm="7" md="7"><Label>{t('duration')+" : "}<br></br><i>{t2('duration')+" : "}</i></Label></Col>
                        {
                            premiumValue.planSelected.premium.duration? 
                                <Col xs="6" sm="5" md="5" className="text-right"><Label>
                                    {premiumValue.planSelected.premium.duration + " " + t('duration.'+ respData.durationMeasurement)}
                                    <br />
                                    {premiumValue.planSelected.premium.duration + " " + t2('duration.'+ respData.durationMeasurement)}
                                </Label></Col>
                            : null
                        } 
                </Row>
                <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                <Row>
                    <Col xs="6" sm="7" md="7"><Label>{t('basicPremium')}<br></br><i>{t2("basicbenefits")}</i></Label></Col>
                    <Col xs="6" sm="5" md="5" className="text-right"><Label>{constant.defaultCurrency} {planSelected.premium? parseInt(planSelected.premium.basicPremium).toLocaleString(constant.defaultLocale): 0}</Label></Col>
                </Row>
                <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                { premiumValue.additionalBenefitsChecked.find(addBenefit => addBenefit.checked === true && planSelected.premium.additionalBenefitPremium > 0) ?
                    <div>
                    <Row>
                        <Col xs="6" sm="7" md="7">
                            <Label>
                                {t('additionalBenefit')} :<br></br>
                                <i>{t2("additionalBenefit")} :</i>
                            </Label>
                        </Col>
                        <Col xs="6" sm="5" md="5" className="text-right"><Label>{constant.defaultCurrency} {planSelected.premium? parseInt(planSelected.premium.additionalBenefitPremium).toLocaleString(constant.defaultLocale): 0}</Label></Col>
                        {  premiumValue.additionalBenefitsChecked.map( (addBenefit, indexAddBenefit) =>
                            addBenefit.checked === true?
                                <Col key={indexAddBenefit} xs="12" sm="12" md="12" className="font-weight-normal mb-3">+ {addBenefit.desc}</Col>
                            :null
                        )}
                    </Row>
                    
                    {/* <Row>                    
                        <Col xs="6" sm="7" md="7"><Label>{t('additionalPremium')}</Label></Col>
                        <Col xs="6" sm="5" md="5" className="text-right"><Label>{constant.defaultCurrency} {planSelected.premium? planSelected.premium.addtionalBenefitPremium:0}</Label></Col>
                    </Row>               
                    <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row> */}
                    </div>: ""
                }

                <Row>
                    <Col xs="6" sm="7" md="7"><Label>{t('totalPremium')}<br></br><i>{t2('totalPremium')}</i></Label></Col>
                    <Col xs="6" sm="5" md="5" className="text-right"><Label>{constant.defaultCurrency} {planSelected.premium ? parseInt(planSelected.premium.grossPremium).toLocaleString(constant.defaultLocale) : 0}</Label></Col>
                </Row>
                <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                
                { planSelected.premium.adminFee !== 0 ? (
                    <div>
                        <Row>
                            <Col xs="6" sm="7" md="7"><Label>{t('adminFee')}<br></br><i>{t2("adminFee")}</i></Label></Col>
                            <Col xs="6" sm="5" md="5" className="text-right"><Label>{constant.defaultCurrency} {planSelected.premium ? parseInt(planSelected.premium.adminFee).toLocaleString(constant.defaultLocale) : 0}</Label></Col>
                        </Row>
                        <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                    </div>
                        ) : null
                }

                {  planSelected.premium.stampDuty !== 0 ? (
                    <div>
                        <Row>
                            <Col xs="6" sm="7" md="7"><Label>{t('stampDuty')}<br></br><i>{t2("stampDuty")}</i></Label></Col>
                            <Col xs="6" sm="5" md="5" className="text-right"><Label>{constant.defaultCurrency} {planSelected.premium ? parseInt(planSelected.premium.stampDuty).toLocaleString(constant.defaultLocale) : 0}</Label></Col>
                        </Row>
                        <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                    </div>
                        ) : null
                }

                {  planSelected.premium.promoDiscountAmount !== 0 && planSelected.premium.promoCode ? (
                    <div>
                        <Row>
                            <Col xs="6" sm="7" md="7"><Label>{t('promoCode')} ({planSelected.premium.promoCode})<br></br><i>{t2("promoCode")} ({planSelected.premium.promoCode})</i></Label></Col>
                            <Col xs="6" sm="5" md="5" className="text-right" style={{color:'red'}}>
                                <Label> {constant.defaultCurrency} {planSelected.premium ? parseInt(planSelected.premium.promoDiscountAmount).toLocaleString(constant.defaultLocale) : 0}</Label>
                            </Col>
                        </Row>
                        <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                    </div>
                        ) : null
                }

                {  planSelected.premium.discountAmount !== 0 ? (
                    
                    <div>
                        <Row>
                            <Col xs="6" sm="7" md="7"><Label>{t('discountAmount')}<br></br><i>{t2("discountAmount")}</i></Label></Col>
                            <Col xs="6" sm="5" md="5" className="text-right"><Label>{constant.defaultCurrency} {planSelected.premium ? parseInt(planSelected.premium.discountAmount).toLocaleString(constant.defaultLocale) : 0}</Label></Col>
                        </Row>
                        <Row><Col xs="12" sm="12" md="12" className="thinBorderBottom mb-3"></Col></Row>
                    </div>
                        ) : null
                }
               
                <Row>
                    <Col xs="6" sm="7" md="7"><Label>{t('grandtotal')}<br></br><i>{t2('grandtotal')}</i></Label></Col>
                    <Col xs="6" sm="5" md="5" className="text-right borderBottom borderTop mb-2"><Label>{constant.defaultCurrency} {planSelected.premium? parseInt(planSelected.premium.grandTotal).toLocaleString(constant.defaultLocale):0}</Label></Col>
                </Row>
                </div>
            </CardBody></Card>
        </div> 
    )
    else return <div>empty</div>
};

export default ConfirmationPlan;