import React, { useState, useEffect } from 'react';
import { Label, Button, Container, Row, Col, Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as urlConstant from './../../constants/urlConstant';
import * as Constant from './../../constants/Constant';
import { useDispatch } from 'react-redux';
import $ from 'jquery';
import { push } from 'connected-react-router';
import { Get } from '../../utils/AxFetch';

const TransactionDetail = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [basicben, setBasicben] = useState({bscbenData:[]});
    const [additionalben, setAdditionalben] = useState({addbenData:[]});
    const [basicIsOpen, setBasicIsOpen] = useState(false);
    const [additionalIsOpen, setAdditionalIsOpen] = useState(false);

    let id = props.location?.state?.selected||"";

    const toggleBasicBenefit = () => setBasicIsOpen(!basicIsOpen);
    const toggleAdditionalBenefit = () => setAdditionalIsOpen(!additionalIsOpen);

    useEffect(() => {
        if(id!==""){
            const retrieveTransaction = async dispatch => {
                let resp = await Get(urlConstant.URL_BASED + urlConstant.URL_TRNX_VIEW + id, null, dispatch);
                if(!resp.isError && resp.respData.data) {
                    populateField(resp.respData.data);
                    setBasicben({bscbenData:resp.respData.data.plan.basicBenefits});
                    setAdditionalben({addbenData:resp.respData.data.plan.additionalBenefits});
                }
            }
            dispatch(retrieveTransaction);
        }
    },[dispatch, id]) 
    
    const populateField = data => {
        $('#productname').text(data.productCode);
        $('#certinsurance').text(data.policyNo);
        $('#proposername').text(data.proposer.name);
        $('#plan').text(data.plan.duration);
        if (data.riskItems.sumInsured) $('#suminsured').text(parseInt(data.riskItems.sumInsured).toLocaleString(Constant.defaultLocale));
        $('#grossPremium').text(data.grossPremium);
        $('#commission').text(data.commissionAmt);
        $('#nettPremium').text(data.nettPremium);
        $('#effectivedate').text(data.issuanceStartDate)
        $('#expirydate').text(data.issuanceEndDate) ;
    }

    return (
        <div>
            <Container>
                <Row className="mt-3"><Col><h2>{t('transaction.enquiry')}</h2></Col></Row>
                <Row className="mt-3"><Col style={{color: '#e80b1c'}}><h4>{t('transaction.detail')}</h4></Col></Row>
                <Row><Col style={{borderBottom: '2px solid #000000'}}></Col></Row>
                
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('transaction.search.cinumber')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="certinsurance"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('transaction.detail.periodofinsurance')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5">
                        <Label>
                            <span id="effectivedate"/><span>{' '}{t('effectivedateto')}{' '}</span><span id="expirydate"/>
                        </Label>
                    </Col>
                </Row>
                <Row className="mt-3">        
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('transaction.listing.proposername')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="proposername"/></Col> 
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('productname')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="productname"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('transaction.detail.plan')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="plan"/></Col>
                </Row>
                <Row className="mt-3">
                    <Button id="basicbenefit" className={"accordion-toggle "+ (basicIsOpen? "collapse-icon":"expand-icon")}
                        onClick={toggleBasicBenefit}>{t('basicbenefits')}</Button>
                </Row>
                <Collapse isOpen={basicIsOpen}>
                    { (basicben.bscbenData.length> 0) ?
                        basicben.bscbenData.map((bben,i) => {
                        let index = i+1;
                        return (    
                            <Row className="mt-3" key={i}>
                                <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('basicbenefit')} {index}</Label></Col>
                                <Col xs="2" sm="1" md="1">:</Col>
                                <Col xs="12" sm="7" md="5"><Label name="basicbenefitdetails">{bben.desc}</Label></Col>
                                <Col xs="12" sm="7" md="3"><Label name="basicbenefitdetails">Rp. {parseInt(bben.displayValue).toLocaleString(Constant.defaultLocale)}</Label></Col>
                            </Row>
                            )
                        }): ''
                    }
                </Collapse>                
                <Row className="mt-3">
                    <Button id="additionalbenefit" className={"accordion-toggle "+ (additionalIsOpen? "collapse-icon":"expand-icon")}
                        onClick={toggleAdditionalBenefit}>{t('additionalbenefits')}</Button>
                </Row>
                <Collapse isOpen={additionalIsOpen}>
                { (additionalben.addbenData.length> 0) ?
                    additionalben.addbenData.map((addben,i) => {
                    let idx = i+1;
                    return (    
                        <Row className="mt-3" key={i}>
                            <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('additionalbenefits')} {idx}</Label></Col>
                            <Col xs="2" sm="1" md="1">:</Col>
                            <Col xs="12" sm="7" md="5"><Label name="additionalbenefitdetails">{addben.desc}</Label></Col>
                            <Col xs="12" sm="7" md="3"><Label name="basicbenefitdetails">Rp. {parseInt(addben.displayValue).toLocaleString(Constant.defaultLocale)}</Label></Col>
                        </Row>
                        )
                    }): ''
                }
                </Collapse>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('suminsured')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="suminsured"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('grossPremium')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="grossPremium"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('transaction.listing.commission')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="commission"/></Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="10" sm={{ size: 3 }} md="3"><Label>{t('transaction.listing.nettPremuim')}</Label></Col>
                    <Col xs="2" sm="1" md="1">:</Col>
                    <Col xs="12" sm="7" md="5"><Label id="nettPremium"/></Col>
                </Row>
            </Container>

            <div style={{textAlign: 'center'}}>
                <Button className="mt-3" onClick={() => dispatch(push('/transactionEnquiry'))}>{t('button.back')}</Button>
            </div>
        </div>
    )
}

export default TransactionDetail;